<template>
  <div v-if="videos.items.length" class="slider-wrapper">
    <i v-if="collection.length > 7" class="before" />
    <div class="slider animes">
      <ul :style="'width:' + ((collection.length * sliderItemWidth) + sliderItemWidth) + 'px'">
        <li v-for="(film, i) in collection" :key="`slider-${videos.type}-${film.id}-${i}`">
          <div class="poster">
            <img
              :data-src="filmPoster(film)"
              :alt="'Постер к ' + film.title_ru"
              src="/static_files/no-poster.png"
              class="lazyload"
            >
          </div>
          <!-- <i class="icon-view" /> -->
          <strong class="name-ru">
            {{
              film.title_ru.indexOf('/') >= 0
                ? film.title_ru.slice(film.title_ru.indexOf('/') + 1)
                : film.title_ru
            }}
          </strong>
          <span v-if="film.genres" class="name-en">
            {{ film.genres[0] }}
          </span>
          <nuxt-link v-if="videos.type === 'download-anime'" :to="`/${videos.type}/${film.kinopoisk_id}`" :title="film.title_ru">
            {{ film.title_ru }} / {{ film.title_en }}
          </nuxt-link>
          <nuxt-link v-else :to="`/${videos.type}/${postUrl(film.sh_id || film.wa_id || film.kinopoisk_id || film.id, film.title_ru)}`" :title="film.title_ru">
            {{ film.title_ru }} / {{ film.title_en }}
          </nuxt-link>
        </li>
        <li v-if="collection.length >= 7" class="collection-show-more">
          <i v-if="collection.length < videos.items.length" class="collection-show-more-plus" @click="sliderArrows('right', $event)" />
          <i v-else class="collection-show-more-minus" @click="sliderArrows('left', $event)" />
        </li>
      </ul>
    </div>
    <div v-if="collection.length > 6" class="arrows">
      <i v-if="collection.length > 7" class="larrow" @click="sliderArrows('left', $event)" />
      <i class="rarrow" @click="sliderArrows('right', $event)" />
    </div>
    <i v-if="collection.length < 60" class="after" />
  </div>
</template>

<script>
export default {
  props: { videos: { type: Object, required: true, default: () => ({ items: [], type: 'watch' }) } },
  data() {
    return {
      collection: []
    }
  },
  computed: {
    sliderItemWidth() {
      return this.$store.state.isDesktop ? 190 : 170
    }
  },
  created() {
    this.collection = this.videos.items.slice(0, 7)
  },
  methods: {
    sliderArrows(side, event) {
      let elem = null
      if (event.target.parentElement.className === 'collection-show-more') {
        elem = event.target.parentElement.parentElement.parentElement
        if (side === 'left') {
          this.collection = this.videos.items.slice(0, 7)
          elem.scrollTo(0, 0)
        } else if (side === 'right') {
          if (this.sliderItemWidth === 190) {
            this.collection = this.videos.items.slice(0, this.collection.length + 5)
            this.$nextTick(() => elem.scrollTo(elem.scrollLeft + this.sliderItemWidth * 7, 0))
          } else {
            this.collection = this.videos.items.slice(0, this.collection.length + 10)
          }
        }
      } else {
        elem = event.target.parentElement.previousElementSibling
        if (side === 'left') {
          if (this.collection.length >= 11)
            this.collection = this.videos.items.slice(0, this.collection.length - 5)
          elem.scrollTo(elem.scrollLeft - 195 - this.sliderItemWidth * 5, 0)
        } else if (side === 'right') {
          if (this.collection.length < this.videos.items.length)
            this.collection = this.videos.items.slice(0, this.collection.length + 6)
          elem.scrollTo(elem.scrollLeft + 195 + this.sliderItemWidth * 6, 0)
        }
      }
      this.$nextTick(() => this.lazyLoadImgs())
    }
  }
}
</script>
